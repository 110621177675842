.mobile-nav {
  display: none;
  padding-bottom: 1rem;

  .nav-item {
    display: block;
    padding: .75rem;

    @include media-breakpoint-down(sm) {
      padding: .5rem .75rem;
    }
  }

  li + .nav {
    padding-left: 1rem;
  }
}
