.tag {
  display: inline-block;
  padding: .25em .4em;
  line-height: 1;
  font-size: 75%;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;

  // Empty tags collapse automatically
  &:empty {
    display: none;
  }
}