.global-footer {
  position: relative;
  padding: 2rem 0 3rem;
  background-color: #2b2c30;
  color: $white;

  a {
    color: $white;

    &:hover {
      color: $red;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 1rem;

    .brand {
      margin-bottom: 1rem;
    }

    li {
      margin-bottom: .75rem;
    }
  }

  &__social {
    .social-icon:not(:first-child) {
      margin-left: .5em;
    }

    @include media-breakpoint-down(md) {
      margin-top: 1rem;
    }

    @include media-breakpoint-up(lg) {
      margin-left: auto;
    }
  }

  &__legal {
    a {
      text-decoration: underline;
    }
  }
}
