.flex {
  display: flex;

  @include media-breakpoint-up(md) {
    &\@md {
      display: flex !important;
    }
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--column {
    flex-direction: column;
  }

  &--justify-between {
    justify-content: space-between;
  }

  &--justify-center {
    justify-content: center;
  }

  &--justify-start {
    justify-content: flex-start;
  }

  @include media-breakpoint-up(sm) {
    &--justify-start\@sm {
      justify-content: flex-start;
    }
  }

  &--justify-end {
    justify-content: flex-end;
  }

  &--align-end {
    align-items: flex-end;
  }

  &--align-center {
    align-items: center;
  }

  &--align-baseline {
    align-items: baseline;
  }

  &-1 {
    flex: 1;
  }
}

@include media-breakpoint-up(lg) {
  .flex\@lg {
    display: flex !important;
  }
}

.items {
  &-center {
    align-items: center;
  }

  &-end {
    align-items: flex-end;
  }

  &-baseline {
    align-items: baseline;
  }
}

.justify {
  &-center {
    justify-content: center;
  }

  @include media-breakpoint-up(md) {
    &-start\@md {
      justify-content: flex-start;
    }

    &-end\@md {
      justify-content: flex-end;
    }
  }
}

.order {
  &-1 {
    order: 1;
  }

  &-2 {
    order: 2;
  }

  &-3 {
    order: 3;
  }

  @include media-breakpoint-up(md) {
    &-1\@md {
      order: 1;
    }
  }

  @include media-breakpoint-up(md) {
    &-2\@md {
      order: 2;
    }
  }

  @include media-breakpoint-up(lg) {
    &-2\@lg {
      order: 2;
    }
  }
}
