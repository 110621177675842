.button {
  display: inline-block;
  padding: .5em .8em;
  border: 2px solid $black;
  color: $black;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  user-select: none;
  border-radius: 0;

  &:focus,
  &:hover {
    background-color: $black;
    color: $white;
  }

  &--pub {
    background-color: $white;
  }

  &--lg {
    font-size: 1.25rem;
  }

  &--primary,
  &--red {
    border-color: $red;
    color: $red;

    &.is-active,
    &:focus,
    &:hover {
      color: $white;
      background-color: $red;
    }
  }

  &--white {
    border-color: $white;
    color: $white;

    &:focus,
    &:hover {
      color: $black;
      background-color: $white;
    }
  }

  &--blue {
    background-color: #0cacc9;
    border-color: #0cacc9;
    color: $white;

    &:focus,
    &:hover {
      background-color: #0cacc9;
    }
  }

  &--filled {
    background-color: currentColor;
  }

  &--sm {
    padding: .25rem .8rem;
  }

  &--xs {
    padding: .15rem .4rem;
  }
}
