.nav-item {
  $_text-regular: #545454;
  $_text-hover: #111;

  color: $_text-regular;
  text-decoration: none;

  &.is-active,
  &:focus,
  &:hover {
    // wat
    color: $red;
  }
}
