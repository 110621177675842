$black: #111;
$white: #fefffe;
$gray: #c5c5c5;
$light-gray: mix($gray, $white, 40%);
$dark-gray: #666;
$blue-gray: #39474d;
$blue: #1491e0;
$red: #b32325;
$yellow: #fecb00;
$menubar-bg-color: rgba(255, 255, 255, .98);
$footer-text-color: #dbdbdb;
