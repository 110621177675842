// Generate box model classes 0, 0.5, :wq

// m = margin
// p = padding

// x = x axis(ie left and right)
// y = y axis(ie top and bottom)

$sizes: (
  -gutter: $grid-gutter,
  0: 0,
  0\.1: 0.1rem,
  0\.2: 0.2rem,
  0\.3: 0.3rem,
  0\.5: 0.5rem,
  1: .75rem,
  2: 1rem,
  3: 2rem,
  4: 3rem,
  5: 4rem,
);

@each $k, $v in $sizes {
  .m#{$k} {
    margin: $v;
  }

  .mt#{$k} {
    margin-top: $v;
  }

  .mb#{$k} {
    margin-bottom: $v;
  }

  .ml#{$k} {
    margin-left: $v;
  }

  .mr#{$k} {
    margin-right: $v;
  }

  .mx#{$k} {
    margin-left: $v;
    margin-right: $v;
  }

  .my#{$k} {
    margin-top: $v;
    margin-bottom: $v;
  }

  .p#{$k} {
    padding: $v;
  }

  .pb#{$k} {
    padding-bottom: $v;
  }

  .pt#{$k} {
    padding-top: $v;
  }

  .pl#{$k} {
    padding-left: $v;
  }

  .pr#{$k} {
    padding-right: $v;
  }

  .px#{$k} {
    padding-left: $v;
    padding-right: $v;
  }

  .py#{$k} {
    padding-top: $v;
    padding-bottom: $v;
  }
}

@each $k, $_ in map-remove($responsive-breakpoints, xs) {
  @include media-breakpoint-up($k) {
    @for $i from 1 through 5 {

      $amount: $i - 1;
      @if $amount < 1 {
        $amount: 0.75;
      }

      .pt#{$i}\@#{$k} {
        padding-top: $amount + rem;
      }
      
      .pb#{$i}\@#{$k} {
        padding-bottom: $amount + rem;
      }

    }
  }
}

@include media-breakpoint-up(lg) {
  .mb0\@lg {
    margin-bottom: map-get($sizes, 0);
  }

  .ml-auto\@lg {
    margin-left: auto;
  }

  .px3\@lg {
    padding-left: map-get($sizes, 3);
    padding-right: map-get($sizes, 3);
  }

  .px1\@lg {
    padding-left: map-get($sizes, 1);
    padding-right: map-get($sizes, 1);
  }

  .pr2\@lg {
    padding-right: map-get($sizes, 2);
  }

  .pl2\@lg {
    padding-left: map-get($sizes, 2);
  }

  .py5\@lg {
    padding-top: map-get($sizes, 5);
    padding-bottom: map-get($sizes, 5);
  }
}

.first-child\(mt0\) {
  > *:first-child {
    margin-top: 0;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

@include media-breakpoint-up(md) {
  .mt0\@md {
    margin-top: map-get($sizes, 0);
  }

  .mb0\@md {
    margin-bottom: map-get($sizes, 0);
  }

  .pl2\@md {
    padding-left: map-get($sizes, 2);
  }

  .px-gutter\@md {
    padding-left: map-get($sizes, -gutter);
    padding-right: map-get($sizes, -gutter);
  }

  .px3\@md {
    padding-left: map-get($sizes, 3);
    padding-right: map-get($sizes, 3);
  }

  .py5\@md {
    padding-top: map-get($sizes, 5);
    padding-bottom: map-get($sizes, 5);
  }

  .pt0\@md {
    padding-top: map-get($sizes, 0);
  }

  .pl3\@md {
    padding-left: map-get($sizes, 3);
  }

  .pt4\@md {
    padding-top: map-get($sizes, 4);
  }

  .mb3\@md {
    margin-bottom: map-get($sizes, 3);
  }

  .ml-auto\@md {
    margin-left: auto;
  }
}

.mw1 {
  max-width: 35rem;
}

.mw2 {
  max-width: 40rem;
}

.mw3 {
  max-width: 45em;
}

.mw250px {
  max-width: 250px;
}

.mw800 {
  max-width: 800px;
}

.w50 {
  width: 50%;
}

@include media-breakpoint-up(md) {
  .w50\@md {
    width: 50%;
  }
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.vh100 {
  height: 100vh;
}

.border-none {
  border: 0;
}
