.company-hero {
  position: relative;

  @include media-breakpoint-up(lg) {
    &__text {
      position: absolute;
      bottom: 2em;
      left: 0;
      width: 100%;
      color: #fff;

      &--bottom {
        top: auto;
        bottom: 5em;
      }
    }
  }
}
