.row {
  @include make-row;
}

.col {
  @include make-col(12);

  &--align-center {
    margin: 0 auto;
  }

  &--no-padding {
    padding: 0;
  }

}

// Generates grid classes for all container breakpoints.
// col-4
// col-2
// col-6@xxl
// col-6@md
@for $i from 1 through 11 {
  .col-#{$i} {
    @include make-col($i)
  }
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

// Exclude xs and xxl breakpoints
@each $k, $_ in map-remove($responsive-breakpoints, xs, xxl) {
  @include media-breakpoint-up($k) {
    @for $i from 1 through 11 {
      .col-#{$i}\@#{$k} {
        @include make-col-span($i);
      }
    }

    .col\@#{$k} {
      @include make-col-span(12);
    }
  }
}

@media screen and (orientation: landscape) {
  @for $i from 1 through 11 {
    .col-#{$i}\@landscape {
      @include make-col-span($i);
    }
  }
}

@media screen and (orientation: portrait) {
  @for $i from 1 through 11 {
    .col-#{$i}\@portrait {
      @include make-col-span($i);
    }
  }
}

$large-gutter: 2rem;

.container--largeGutter .row {
  @include media-breakpoint-up(md) {
    margin-left: ($large-gutter / -2);
    margin-right: ($large-gutter / -2);

    > .col {
      padding-left: ($large-gutter / 2);
      padding-right: ($large-gutter / 2);
    }
  }
}
