.series-display {
  @include media-breakpoint-down(xl) {
    max-width: 100%;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1250px;
  }

  @include media-breakpoint-only(xl) {
    max-width: 1150px;
  }
}
