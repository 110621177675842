.nav-shadow {
  position: relative;
  text-shadow: 1px 1px 6px rgba(0,0,0,.5);

  &.is-active {
    &:after {
      // mimic border-bottom 2px, adding shadow
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 2px;
      content: '';
      background: white;
      box-shadow: 1px 1px 6px rgba(0,0,0,.5);
    }
  }
}
