.dn {
  display: none;
}

@include media-breakpoint-up(md) {
  .db\@md {
    display: block;
  }

  .dib\@md {
    display: inline-block;
  }

  .dn\@md,
  .none\@md {
    display: none;
  }

  .visible\@md {
    display: block;
  }
}

@include media-breakpoint-up(lg) {
  .dib\@lg {
    display: inline-block;
  }

  .hide\@lg {
    display: none;
  }

  .visible\@lg {
    display: block;
  }
}
