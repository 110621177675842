a {
  $_link-color: #983025;
  color: $_link-color;
  text-decoration: none;

  &:focus,
  &:hover {
    // scss-lint:disable ColorVariable
    color: mix(#000, $_link-color, 30%);
    // scss-lint:enable ColorVariable
  }

}

.link--underline {
  	&:focus,
  	&:hover {
	  	text-decoration: underline;
  	}
  }

