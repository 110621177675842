// Slimed down version of:
// https://cdnjs.cloudflare.com/ajax/libs/Swiper/3.3.1/css/swiper.css
.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  // Fix of Webkit flickering
  z-index: 1;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
  transform: translate3d(0, 0, 0);
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transform: translate3d(0, 0, 0);
}
