.hero {
  height: $hero-height;

  &--sm {
    height: $hero-height / 2;
  }

  @include media-breakpoint-down(lg) {
    height: 0;
    padding-bottom: 56.25%;

    &--sm {
      padding-bottom: 37.5%;
    }
  }
}
