// Company benefits section.
.cbs {
  background-color: $blue-gray;

  &__overlay {
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 150px;
      content: '';
      background: linear-gradient(rgba($blue-gray, 0), rgba($blue-gray, 1));
    }
  }
}
