.site {
  &__content {
    flex: 1;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 42px;
  }
}

.ie11 .site__content {
  flex: 1 0 auto;
  min-height: 80vh;
}

body > .age-gate {
  margin-top: 42px;
  min-height: 80vh; // ie11 fix
}