.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.t0 {
  top: 0;
}

.t1 {
  top: 1rem;
}

.t2 {
  top: 2rem;
}

.b0 {
  bottom: 0;
}

.b0\! {
  bottom: 0 !important;
}

.b6\! {
  bottom: 5rem !important;
}

.l0 {
  left: 0;
}

.l-gutter {
  left: $grid-gutter;
}

.r0 {
  right: 0;
}

.z2 {
  z-index: 2;
}

.z3 {
  z-index: 3;
}

.z4 {
  z-index: 4;
}

.z5 {
  z-index: 5;
}
