.pointer {
  cursor: pointer;
}

.cursor {

  &--pointer {
    cursor: pointer;
  }

  &--default {
    cursor: default;
  }

  &--none {
    cursor: none;
  }

  &--zoomin {
    cursor: zoom-in;
  }

  &--zoomout {
    cursor: zoom-out;
  }

}
