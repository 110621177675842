.swiper-button {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 4;

  &-prev {
    left: 0.5rem;
  }

  &-next {
    right: 0.5rem;
  }

  &-disabled {
    display: none;
  }
}
