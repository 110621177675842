.brand {
  display: block;

  svg,
  img {
    min-width: 128px;
    max-width: 100%;
    height: auto;

    path {
      fill: $black;
    }
  }

  &--white {
    svg path, svg use {
      fill: $white;
    }
  }

  &--red {
    svg path, svg use {
      fill: $red;
    }
  }

}

// Work around IE's inability to size svg images correctly
.ie11 .brand {
  svg, img {
    min-width: 150px;
    height: 30px;
  }
}