.cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.contain {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
