.nav {
  margin: 0;
  padding-left: 0;
  list-style: none;

  &--horizontal {
    li {
      display: inline-block;
    }
  }

  &--gutters {
    > li:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
