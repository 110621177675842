// Some stuff borrowed from:
// http://typecast.com/images/uploads/modernscale.css

body {
  $_bg-color: #fff;
  background-color: $_bg-color;
  color: $black;
  font-family: $copy-font;
  font-size: 100%;
}

body,
caption,
th,
td,
input,
textarea,
select,
option,
legend,
fieldset,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size-adjust: 0.5;
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
  font-family: $heading-font;
  font-weight: inherit;
  line-height: 1.2;
}

h1 {
  font-size: 2.5em;
  font-weight: 800;

  @include media-breakpoint-down(sm) {
    font-size: 2.5256em;
  }
}

h2 {
  font-size: 2.369em;
  font-weight: 800;

  @include media-breakpoint-down(sm) {
    font-size: 1.8952em;
  }
}

h3 {
  font-size: 1.777em;

  @include media-breakpoint-down(sm) {
    font-size: 1.4216em;
  }
}

h4 {
  font-size: 1.333em;

  @include media-breakpoint-down(sm) {
    font-size: 1.064em;
  }
}

p {
  line-height: 1.45;
}

blockquote {
  display: inline-block;
  position: relative;

  &:before,
  &:after {
    color: mix($gray, #fff, 50%);
    font-family: $heading-font;
    font-size: 5rem;
    font-weight: 400;
    line-height: 0;
  }

  &:before {
    position: absolute;
    top: 2rem;
    left: -2rem;
    content: "\201C"
  }

  &:after {
    position: absolute;
    bottom: 0;
    right: -2rem;
    content: "\201D"
  }
}

hr {
  border: 1px solid #e4e4e4;
  margin: 2rem 0;
}

.fonts-loaded {
  body {
    font-family: $copy-font-loaded;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $heading-font-loaded;
  }
}
