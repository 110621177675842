.red-hover:hover,
.red-hover:focus,
.red\:hover:hover,
.red\:hover:focus,
.red {
  color: $red;
}

.yellow {
  color: $yellow;
}

.gray {
  color: $gray;

  &\! {
    color: $gray !important;
  }
}

.blue-gray {
  color: #2b2c30;
}

.bg-blue {
  background-color: $blue;
}

.bg-blue-800 {
  background-color: rgb(15, 17, 29);
}

.blue {
  color: $blue;
}

.blue--muted {
  color: #84caf6;
}

.blue\:hover:hover,
.blue\:hover:focus {
  color: $blue;
}

.bg-blue-gray {
  background-color: #2b2c30;
}

.bg-light-blue {
  background-color: #cce9ed;
}

.dark-gray,
.text-dark {
  color: #666;
}

.dark-gray\! {
  color: #666 !important;
}

.black\:hover:hover,
.black\:hover:focus,
.black {
  color: $black;
}

.black\! {
  color: $black !important;
}

.white\:hover:hover,
.white\:hover:focus,
.white-hover:hover,
.white-hover:focus,
.white {
  color: $white;
}

.white\! {
  color: $white !important;
}

.bb-gray {
  border-bottom: 2px solid $gray;
}

.border-black {
  border: 2px solid $black;
}

.border-inactive {
  border: 2px solid #89938c;
}

.ba-white,
.border-white {
  border: 2px solid $white;
}

.ba-red,
.ba-red\:hover:hover,
.ba-red\:hover:focus,
.border-red {
  border: 2px solid $red;
}

$_red500: mix(#c30, #000, 5%);

.ba-red-500 {
  border: 2px solid $_red500;
}

.red-500\:hover:hover,
.red-500\:hover:focus,
.red-500 {
  color: $_red500
}

.border-bright-red {
  border: 2px solid $red;
}

@include media-breakpoint-up(lg) {
  .bl-red\@lg {
    border-left: 2px solid $red;
  }

  .br-red\@lg {
    border-right: 2px solid $red;
  }
}

.border-r-red {
  border-right: 2px solid $red;
}

.border-gray {
  border: 2px solid $gray;
}

.bg-red-hover:hover,
.bg-red-hover:focus,
.bg-red {
  background-color: $red;
}

.bg-bright-red {
  background-color: #b72009;
}

.red-500 {
  color: #b72009;
}

.bg-gray {
  background-color: $gray;
}

.bg-dark-gray {
  background-color: mix(#000, $gray, 80%);
}

.bg-black800 {
  background-color: #000;
}

.bg-black\:hover:hover,
.bg-black\:hover:focus,
.bg-black {
  background-color: $black;
}

.bg-inactive {
  background-color: #89938c;
}

.bg-b {
  background-color: #1a1b1d;
}

.bg-light-gray {
  background-color: #f7f7f7;
}

.bg-black-fade-out {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .8));
}

.bg-hover-white:hover,
.bg-hover-white:focus,
.bg-white {
  background: $white;
}

.bg-white\:hover:hover,
.bg-white\:hover:focus {
  background: $white;
}

.bg-white\! {
  background: $white !important;
}

.bg-white-transparent {
  background: rgba(255, 255, 255, .8);
}

.bg-white--transparent {
  background: rgba(255, 255, 255, .98);
}

.bg-transparent {
  background: transparent;
}

.hover-overlay {
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .1);
    content: "";
    opacity: 0;
    z-index: 1;
    transition: opacity .15s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
}

.bg-gray-200\:hover {
  transition: background-color .1s ease-in-out;
}

.bg-gray-200\:hover:hover,
.bg-gray-200\:hover:focus {
  background-color: rgba(0, 0, 0, .02);
}

.bg-transparent-black {
  background-color: rgba(#000, .63);
}

.bg-transparent-white-gradient {
  background: linear-gradient(rgba(#fff, .76), rgba(#000, .76));
}
