@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $responsive-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

@mixin make-container($gutter: $grid-gutter) {
  margin-left: auto;
  margin-right: auto;
  padding-left: ($gutter);
  padding-right: ($gutter);
  @include make-container-max-widths;
}

.container {
  @include make-container;

  @include media-breakpoint-down(xl) {
    &--only-xl {
      padding: 0;
      max-width: 100%;
    }
  }
}

.container--largeGutter {
  @include media-breakpoint-up(md) {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}
