// @NOTE: Changing these variables may wreak havoc on your peers and break a js
// script or two.

$responsive-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
);

$container-max-widths: (
  sm: 576px,
  md: 720px,
  lg: 940px,
  xl: 1140px,
  xxl: 1380px
);
