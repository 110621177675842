.form-input {
  padding: 8px 4px;
  border: 0;
  background: transparent;
  border-bottom: 2px solid #bcc4d5;
  color: #bcc4d5;
  outline: none;

  &:hover,
  &:focus {
    border-color: $red;
    color: $red;
  }

  &--wide {
    width: 25em;
  }
}
