.asset-vh100 {}

  .asset-vh100 .game-block-asset {
    height: calc(100vh - 42px);
  }

  .asset-vh100 .game-block-chevron {
    display: block;
  }

.game-block-link {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.game-block-content {
  align-items: start;
}

.content-top-center .game-block-content {
  align-items: start;
  justify-content: center;
}

.content-top-left .game-block-content {
  align-items: start;
  justify-content: start;
}

.content-top-right .game-block-content {
  align-items: start;
  justify-content: end;
}

.content-center-center .game-block-content {
  align-items: center;
  justify-content: center;
}

.content-center-left .game-block-content {
  align-items: center;
  justify-content: start;
}

.content-center-right .game-block-content {
  align-items: center;
  justify-content: end;
}

.content-bottom-center .game-block-content {
  align-items: end;
  justify-content: center;
}

.content-bottom-left .game-block-content {
  align-items: end;
  justify-content: start;
}

.content-bottom-right .game-block-content {
  align-items: end;
  justify-content: end;
}


// TODO: This should probably be more reusable (Though might not be worth the effort with the redesign looming)

$chevronSize: 64px;

.game-block-chevron {
  bottom: 4px;
  display: none;
  height: $chevronSize / 2;
  left: calc(50% - #{$chevronSize / 2});
  overflow: hidden;
  position: absolute;
  width: $chevronSize;

  &.down {
    transform: rotate(180deg);
  }

  &.left {
    transform: rotate(270deg);
  }

  &.right {
    transform: rotate(90deg);
  }

}

  .game-block-chevron::before,
  .game-block-chevron::after {
    border: ($chevronSize / 24) solid #fff;
    box-shadow: -3px -3px 2px 0 rgba(0, 0, 0, 0.5);
    content: '';
    display: block;
    height: 100%;
    left: 50%;
    position: relative;
    top: 50%;
    transform: translate(-50%, 0) rotate(45deg);
    width: 50%;
    z-index: 2;
  }

  .game-block-chevron::after {
    top: -16%;
    z-index: 1;
  }

@media screen and (orientation: portrait) {
  .game-block-chevron {
    left: 4px;
  }
}