#fb-news .fb-page,
#fb-news .fb-page iframe[style],
#fb-news .fb-page span {
  width: 100% !important;
}

#fb-page .fb-page,
#fb-page .fb-page iframe[style],
#fb-page .fb-page span {
  width: 100% !important;
}

.hack-001 {
  /* Fixes mis-aligned titles on iPad... ;_; */
  @include media-breakpoint-only(md) {
    width: 300px;
  }
}
