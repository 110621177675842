html {
  font-size: 1em;
}

* {
  box-sizing: border-box;

  &:before, 
  &:after {
    box-sizing: inherit;
  }
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
