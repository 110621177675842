.ratio-16x9 {
  padding-bottom: 56.25%;
}

.ratio-22x9 {
  padding-bottom: 40.90%;
}

.ratio-16x4\.4 {
  padding-bottom: 27.5%;
}

.ratio-1x1 {
  padding-top: 100%;
}

.ratio-8x3 {
  padding-bottom: 37.5%;
}
