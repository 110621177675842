.is-visible {
  display: block !important;
}

.db,
.block {
  display: block;
}

.none {
  display: none;
}

@media screen and (orientation: landscape) {
  .none\@landscape {
    display: none;
  }
}

@media screen and (orientation: portrait) {
  .none\@portrait {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .dn\@md {
    display: none;
  }
}

@include media-breakpoint-up(lg) {
  .dn\@lg {
    display: none;
  }
}

.dib,
.inline-block {
  display: inline-block;
}

.overflow-hidden {
  overflow: hidden;
}
