.episode-block {
  // assign $_p to reference top-level class.
  $_p: &;

  &__body {
    position: relative;
    color: #111;
    transition: transform .15s cubic-bezier(0.4, 0, 0.2, 1);
    
    @include media-breakpoint-down(lg) {
      padding: 1em;
      margin-bottom: 4px;
    }

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, .97);

      &.slide-in-left {
        transform: translateX(-100%);
      }

      &.slide-in-right {
        transform: translateX(100%);
      }

      // ¯\_(ツ)_/¯ shikata ga nai
      // .episode-block:hover > .episode-block__body
      #{$_p}:hover > & {
        transform: translateX(0);
      }
    }
  }
}
