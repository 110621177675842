.theater-display {
  height: $hero-height;

  @include media-breakpoint-down(lg) {
    height: 0;
    padding-bottom: 56.25%;
  }
}

.theater-controls {
  &__container {
    @include media-breakpoint-down(xl) {
      max-width: 100%;
    }
  }

  &__swiper {
    padding: 4px 0;
  }
}
