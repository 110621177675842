@mixin make-row($gutter: $grid-gutter) {
  display: flex;
  flex-wrap: wrap;
  margin-left: ($gutter / -2);
  margin-right: ($gutter / -2);
}

@mixin make-col($size, $columns: $grid-columns, $gutter: $grid-gutter) {
  position: relative;
  min-height: 1px;
  padding-right: ($gutter / 2);
  padding-left: ($gutter / 2);
  @include make-col-span($size, $columns);
}

@mixin make-col-span($size, $columns: $grid-columns) {
  flex: 0 0 percentage($size / $columns);
  max-width: percentage($size / $columns);
}
