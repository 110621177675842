.ttu,
.uppercase {
  text-transform: uppercase;
}

.normal {
  font-weight: normal;
}

.lh1 {
  line-height: 1;
}

.lh-sm {
  line-height: 1.2;
}

.tac,
.text-center {
  text-align: center;
}

.kerning-1 {
  letter-spacing: .75px;
}

.text-right {
  text-align: right;
}

@include media-breakpoint-up(md) {
  .text-right\@md {
    text-align: right;
  }
}

@include media-breakpoint-down(sm) {
  .text-left\@sm {
    text-align: left;
  }
}

.text-legal {
  font-size: .8em;
}

.text-small {
  font-size: .9em;
}

@include media-breakpoint-up(sm) {
  .text-small\@ns {
    font-size: .9rem !important;
  }
}

.text-smaller {
  font-size: .75em;
}

.text-medium {
  font-size: 1.1em;
}

.text-large {
  font-size: 1.25em;
}

@include media-breakpoint-up(md) {
  .text-large\@md {
    font-size: 1.25em;
  }
}

.text-larger {
  font-size: 1.5em;
}

.text-bold {
  font-weight: 800;
}

.text-stat {
  font-family: $heading-font;
  font-size: 2.25em;
  font-weight: 200;
  line-height: 1em;
}

.slab {
  font-family: $heading-font;
}

.copy {
  font-family: $copy-font;
  line-height: 1;
}

.fonts-loaded {
  .slab {
    font-family: $heading-font-loaded;
  }
  
  .copy {
    font-family: $copy-font-loaded;
  }
}

.bold {
  font-weight: 800;
}

.italic {
  font-style: italic;
}

.underline {
  border-bottom: 1px solid currentColor;
}
