.hero-puller {
  @include media-breakpoint-up(lg) {
    margin-top: -300px;

    &--md {
      margin-top: -150px;
    }

    &--sm {
      margin-top: -75px;
    }
  }
}
