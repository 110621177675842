.preview-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: top right;
  opacity: .7;

  &--video {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAP1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////9Du/pqAAAAFHRSTlMAAQcQKEVGXoiWmZq/wNrb3Oj6/nO3ZrwAAAA+SURBVHgBY6AZEOBkxhQUERFkZ8QQBAJeFiyCIkIcTKiCEMDPikVQRJgLmyA3Fu1sRFnEw0KM4/mA3qQxAABQGAU2EnhYdgAAAABJRU5ErkJggg==");
  }

  &--image {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAAS1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////+DmQsHAAAAGHRSTlMA2vE+9+KP7dLIwKuceHZtZiwaFgiqU1LB5ldlAAAAc0lEQVR42m3Oyw7CMAxE0SG2k6ZJ3wXm/78Ujai6QJyF5bsb/DM9ZLp7oAyQ02zh12J2oukrec5FT4NuPQAclc9MJNI3tNSweQlXV3SSHZUs6hEjr5vUgRfJN+JqZthsyFRT+o69U7Ttpo2rpofTQ8+KHx9n6woT1m7TjAAAAABJRU5ErkJggg==");
  }
}
