.menutar-container {
  position: relative;
  margin-right: 1rem;
  width: 75px;

  @include media-breakpoint-up(xl) {
    width: 150px;

    .menutar__image {
      margin: -20px -15px 0 0;
    }
  }
}

.menutar {
  position: absolute;
  width: 75px;
  height: 44px;
  top: 0;
  left: 0;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;

    @include media-breakpoint-up(xl) {
      margin: -20px -15px 0 15px;
    }
  }

  @include media-breakpoint-up(xl) {
    width: 150px;
  }
}

.menubar-icon {
  margin-right: .25rem;
  margin-left: .25rem;

  img {
    display: block;
    max-width: 26px;
  }
}
