//
// ▄▄▄█████▓▄▄▄█████▓  ▄████      ██████  ▄████▄    ██████   ██████
// ▓  ██▒ ▓▒▓  ██▒ ▓▒ ██▒ ▀█▒   ▒██    ▒ ▒██▀ ▀█  ▒██    ▒ ▒██    ▒
// ▒ ▓██░ ▒░▒ ▓██░ ▒░▒██░▄▄▄░   ░ ▓██▄   ▒▓█    ▄ ░ ▓██▄   ░ ▓██▄
// ░ ▓██▓ ░ ░ ▓██▓ ░ ░▓█  ██▓     ▒   ██▒▒▓▓▄ ▄██▒  ▒   ██▒  ▒   ██▒
//   ▒██▒ ░   ▒██▒ ░ ░▒▓███▀▒   ▒██████▒▒▒ ▓███▀ ░▒██████▒▒▒██████▒▒
//   ▒ ░░     ▒ ░░    ░▒   ▒    ▒ ▒▓▒ ▒ ░░ ░▒ ▒  ░▒ ▒▓▒ ▒ ░▒ ▒▓▒ ▒ ░
//      ░        ░      ░   ░    ░ ░▒  ░ ░  ░  ▒   ░ ░▒  ░ ░░ ░▒  ░ ░
//      ░        ░      ░ ░   ░    ░  ░  ░  ░        ░  ░  ░  ░  ░  ░
//                     ░          ░  ░ ░            ░        ░
//                                                           ░

@import "variables/breakpoints";
@import "variables/grid";
@import "variables/colors";
@import "variables/fonts";
@import "variables/misc";

@import "vendor/normalize";
@import "vendor/swiper";

@import "tools/breakpoints";
@import "tools/grid";
@import "tools/grid-classes";

@import "base/all";
@import "objects/all";
@import "components/all";
@import "trumps/all";
@import "hacks/all";

@import "layouts/game";

.br--\!last {
  > *:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, .06);
  }
}

.modal-overlay.is-active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: rgba(0, 0, 0, .9);
  z-index: 6;
}

.modal-container {
  &.is-active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 7;

    @include media-breakpoint-down(sm) {
      overflow-y: scroll;
    }

    @include media-breakpoint-up(sm) {
      padding-top: 5rem;
    }
  }
}

.modal {
  position: relative;
  max-width: 800px;
  width: 100%;
  margin: 2rem auto;
  background: $white;
  z-index: 7;

  &--sm {
    max-width: 400px;
  }

  &--lg {
    max-width: 1024px;
  }

  &__title {
    display: flex;
    align-items: baseline;
    padding: 1rem;
    background-color: $black;
    color: $white;
  }

  &__body {
    padding: 2rem;
    background-color: $white;
  }

  &__footer {
    border-top: 2px solid #efefef;
  }

  label {
    margin-bottom: .25rem;
    color: $dark-gray;
    font-size: .9rem;
  }
}

.modal-content {
  display: block;

  &.hidden {
    display: none;
  }
}

.list {
  list-style-type: none;
}

.limit-img {
  img {
    display: block;
    max-width: 100%;
  }
}

.p-news {
  background-color: #bcc6c7;
}

.publish-display {
  border: 4px solid;
  border-top: 0;

  @include media-breakpoint-down(lg) {
    border-left: 0;
    border-right: 0;
  }
}

.bg-pos-b {
  background-position: bottom;
}

.f {
  &1 {
    font-size: 3rem;
  }

  &2 {
    font-size: 2.25rem;
  }

  &3 {
    font-size: 1.5rem;
  }

  &4 {
    font-size: 1.25rem;
  }

  &5 {
    font-size: 1rem;
  }

  &6 {
    font-size: .875rem;
  }

  &7 {
    font-size: .8rem;
  }

  @include media-breakpoint-up(md) {
    &2\@md {
      font-size: 2.25rem;
    }
  }

  @include media-breakpoint-up(lg) {
    &1\@lg {
      font-size: 3rem;
    }

    &6\@lg {
      font-size: .875rem;
    }
  }
}

.home-bg {
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;

  @include media-breakpoint-down(md) {
    background-image: none !important;
  }
}

.home-hero {
  @include media-breakpoint-down(sm) {
    .js-character {
      background-image: none !important;
    }

    svg {
      display: none;
    }

    .js-youtube {
      opacity: 1 !important;
    }
  }

  @include media-breakpoint-up(md) {
    .js-youtube {
      visibility: hidden;
    }
  }
}

.home-hero:focus svg polygon,
.home-hero:hover svg polygon {
  fill: white;
}

.h-chev {
  position: absolute;
  top: 0;
  width: 24px;
  height: 100%;
  opacity: .3;
  z-index: 9001;

  &--left {
    left: .5rem;

    &--outside {
      left: -1.5rem;
    }
  }

  &--right {
    right: .5rem;

    &--outside {
      right: -1.5rem;
    }
  }

  &:hover {
    opacity: 1;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.select-none {
  user-select: none;
}

.mt0\:first-child :first-child {
  margin-top: 0;
}

.mb0\:last-child :last-child {
  margin-bottom: 0;
}

.fill-white {
  /* Required for purchase-options component */
  path,
  polygon {
    fill: white;
  }
}

.bn {
  border-style: none;
  border-width: 0;
}

.invert-images\:hover:focus img,
.invert-images\:hover:hover img {
  filter: invert(100%);
}

/* series page: exclusive content */
.c-ec {
  &_container {
    position: absolute;
    bottom: .25rem;
    left: .5rem;

    @include media-breakpoint-up(md) {
      left: 2rem;
      bottom: 1rem;
    }
  }

  &_yt {
    margin: 0;
    max-width: 15em;
    line-height: 1.1;
    font-size: .9rem;

    @include media-breakpoint-up(sm) {
      font-size: 1.125rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.375rem;
    }
  }

  &_h {
    margin: 0;
    font-size: 1.45rem;

    @include media-breakpoint-up(md) {
      font-size: 2.25rem;
    }
  }
}

.o {
  &\.3 {
    opacity: .3;
  }

  &1\:hover:hover,
  &1\:hover:focus {
    opacity: 1;
  }
}

.c-player {
  display: flex;
  padding: 4px;
  background-color: white;

  &__sidebar {
    position: relative;
    flex: 0 1 19.85%;
    margin-right: 4px;
    overflow: hidden;
  }

  &__viewport {
    flex: 1 1 auto;
    background-color: white;
  }
}

.vcarousel-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.vcarousel-track {
  transition: transform .3s cubic-bezier(0.4, 0, 0.2, 1);
}

.vcarousel-button {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 4;
  width: 100%;
  filter: drop-shadow(2px 2px 5px black);

  img {
    max-width: 35px;
  }

  &--next {
    bottom: 0;
  }

  &--prev {
    top: 0;
  }
}

.z9001 {
  z-index: 9001;
}

/* type-scale */
.ts {
  &1 {
    // 30px
    font-size: 1.875rem;
    @include media-breakpoint-up(md) {
      // 46px
      font-size: 2.875rem;
    }
  }

  &2 {
    // 26px
    font-size: 1.625rem;
    @include media-breakpoint-up(md) {
      // 36px
      font-size: 2.25rem;
    }
  }

  &3 {
    // 20px
    font-size: 1.25rem;
    @include media-breakpoint-up(md) {
      // 28px
      font-size: 1.75rem;
    }
  }

  &-copy,
  &4 {
    // 18px
    font-size: 1.125rem;
    line-height: 1.2;
    @include media-breakpoint-up(md) {
      // 22px
      font-size: 1.375rem;
    }
  }

  &5 {
    // 16px
    font-size: 1rem;
    @include media-breakpoint-up(md) {
      // 18px
      font-size: 1.125rem;
    }
  }

  &6 {
    // 14px
    font-size: .875rem;
    @include media-breakpoint-up(md) {
      // 16px
      font-size: 1rem;
    }
  }

  &7 {
    font-size: .875rem;
  }
}

.invert {
  filter: invert(100%);
}

.inner-container {
  @include media-breakpoint-up(md) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @include media-breakpoint-up(lg) {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}

.ic {
  padding-left: .5rem;
  padding-right: .5rem;

  @include media-breakpoint-up(md) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.promo-block {
  max-width: 20.3125rem;
  margin: 3.75rem;

  &--left {
    margin-left: auto;
    text-align: right;
  }
}

.center-iframes {
  iframe {
    display: block !important;
    margin: 1rem auto !important;
  }
}

.center-imgs {
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
